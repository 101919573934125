import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const QuantityField = ({
  value,
  label,
  divClass,
  labelClasses,
  buttonClasses,
  buttonEffect,
  buttonType,
  inputClasses,
  inputPlaceholder,
  inputValue,
  inputName,
  isDisable = false,
  onChange = () => {},
}) => {
  const effect = {
    filled:
      "z-0 before:content-[''] before:z-[-1] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-victoria-600/80 before:scale-x-0 before:transition-all before:duration-200 hover:before:scale-x-100 before:origin-left",
    hollow:
      "z-0 before:content-[''] before:z-[-1] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-slate-100 before:scale-x-0 before:transition-all before:duration-200 hover:before:scale-x-100 before:origin-left border border-slate-200 bg-slate-50 hover:border-slate-300",
  };

  useEffect(() => {
    if (value) {
      setNum(value);
    }
  }, [value]);

  let [num, setNum] = useState(1);
  let incNum = () => {
    if (num < 99) {
      setNum(Number(num) + 1);
      onChange(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 1) {
      setNum(num - 1);
      onChange(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Prevent entering the minus sign if the current value is non-negative
    if (e.key === "-" && e.target.value >= 0) {
      e.preventDefault();
    }
  };

  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-950 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <div className={classNames("relative flex", divClass)}>
        <input
          type={"number"}
          className={classNames("text-center", inputClasses)}
          placeholder={inputPlaceholder}
          name={inputName}
          defaultValue={inputValue}
          value={num}
          min={0}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <div className="w-[2rem] 2xl:w-14 overflow-hidden">
          <button
            type={buttonType}
            className={classNames(
              "relative z-[1] w-12 h-12 min-w-[48px] bg-victoria text-white !rounded-tr-md",
              buttonClasses,
              effect[buttonEffect]
            )}
            onClick={decNum}
            disabled={isDisable}
          >
            <i className="fa-solid fa-minus"></i>
          </button>
          <button
            type={buttonType}
            className={classNames(
              "relative z-[1] w-12 h-12 min-w-[48px] bg-victoria text-white !rounded-br-md",
              buttonClasses,
              effect[buttonEffect]
            )}
            onClick={incNum}
            disabled={isDisable}
          >
            <i className="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default QuantityField;
