import React, { useEffect, useState } from "react";
import Input from "../form/Input";
import Select from "../form/Select";
import BillingAddress from "./BillingAddress";
import Button from "../form/Button";
import Modal from "../elements/modal/Modal";
import ShippingAddressModal from "./ShippnigAddressModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { classNames } from "../../helpers/classNames";

const ShippingAddress = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const [shippingAddressData, setShippingAddressData] = useState({
    heading: "Address Information",
    subheading:
      "Shipping <span class='text-victoria font-extrabold'>Address</span>",
    changeaddress: [
      {
        _id: 1,
        name: "Tania",
        lastname: "Das",
        streetaddress: "Benami",
        apt: "Apartment 206",
        city: "Kolkata",
        province: "state",
        postalcode: "6563323",
        mobile: "4123658",
        default: true,
      },

      {
        _id: 2,
        name: "Binita",
        lastname: "Das",
        streetaddress: "Benami",
        apt: "Apartment 206",
        city: "Kolkata",
        province: "state",
        postalcode: "6563323",
        mobile: "4123658",
        default: false,
      },
    ],
  });

  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  const addressData = {
    billingAddress: {
      address: "Prince Anwar Shah Road",
      city: "kolkata",
      state: "Newfoundland and Labrador",
      pin: 700012,
    },
    shippingAddress: {
        location: "Prince Anwar Shah Road",
        city: "kolkata",
        state: "Newfoundland and Labrador",
        pin: 700012,
      },
  };

  const billingAddress =
    addressData && addressData?.billingAddress && addressData?.billingAddress;

  const shippingAddress =
    addressData && addressData?.shippingAddress && addressData?.shippingAddress;

  const validateAndNavigate = () => {
    if (!sameBillingAddress) {
      if (!billingAddress.address) {
        toast.error("Billing address is required.");
        return;
      }
      if (!billingAddress.state) {
        toast.error("Billing state is required.");
        return;
      }
      if (!billingAddress.city) {
        toast.error("Billing city is required.");
        return;
      }
      if (!billingAddress.pin) {
        toast.error("Billing pin is required.");
        return;
      }
    }

    if (!shippingAddress.location) {
      toast.error("Shipping location is required.");
      return;
    }
    if (!shippingAddress.state) {
      toast.error("Shipping state is required.");
      return;
    }
    if (!shippingAddress.city) {
      toast.error("Shipping city is required.");
      return;
    }
    if (!shippingAddress.pin) {
      toast.error("Shipping pin is required.");
      return;
    }

    const addressData = {
      shippingAddress: shippingAddress && shippingAddress,
      billingAddress: sameBillingAddress ? shippingAddress : billingAddress,
    };

    // console.log(addressData, "dfffdf");
    navigate("/paymentinformation", { state: addressData });
  };
  return (
    <>
      <div className="xl:w-3/5  md:px-0 px-5">
        {/* <div className="bg-red-600 p-2 pl-5 uppercase text-white text-xl font-semibold rounded-md">
          {ShippingAddressData.heading}
        </div> */}
        <div>
          <div className="flex items-center pt-7 md:pb-3 pb-1 space-x-2">
            <div
              className=" uppercase text-black md:text-2xl text-xl font-medium rounded-md"
              dangerouslySetInnerHTML={{
                __html: shippingAddressData.subheading,
              }}
            ></div>

            <div className="text-2xl">
              <i className="fa-solid fa-circle-question"></i>
            </div>
          </div>
          <hr className="w-full border-1 border-slate-500 pb-8"></hr>
          <div className="">
            {shippingAddress && (
              <div className="flex space-x-5">
                <div
                  className={classNames(
                    "relative overflow-hidden bg-slate-50 border border-victoria !shadow-lg rounded-md p-3 z-0"
                  )}
                >
                  <div className="flex items-center">
                    <div className="flex-1">
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">Address :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.location || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">City :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.city || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">State :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.stateName || "N/A"}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-0">
                        <div className="text-xs font-bold">Postalcode :</div>
                        <div className="md:text-sm text-xs">
                          {shippingAddress?.pin || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="ml-4 mt-12">
                      <Button
                        buttonClasses={"!bg-transparent !text-red-600 "}
                        buttonLabel=""
                        buttonLabelClasses="!text-base"
                        buttonIcon={"fa-regular fa-pen"}
                        buttonIconPosition={"left"}
                        buttonEffect={"filled"}
                        buttonFunction={() => {
                          setIsOpen(true);
                          setAddressDetails(shippingAddress);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <ShippingAddressForm></ShippingAddressForm> */}
          </div>
          {!shippingAddress && (
            <Button
              buttonClasses={"bg-red-600 hover:!bg-black !px-5"}
              buttonLabel="Add Address"
              buttonLabelClasses="!text-base"
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonEffect={"filled"}
              buttonFunction={() => setIsOpen(true)}
            />
          )}
          <div className=" pt-8"></div>
          <Modal
            size="xl4"
            title="Shipping Address"
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <ShippingAddressModal
              onClose={() => setIsOpen(false)}
              setDetails={setAddressDetails}
              details={addressDetails}
            />
          </Modal>
        </div>
        <BillingAddress
          billingAddressData={billingAddress}
          sameBillingAddress={sameBillingAddress}
          setSameBillingAddress={setSameBillingAddress}
        />
        <div className="flex justify-end pt-8">
          <Button
            buttonClasses={"bg-gray-600 hover:!bg-black !px-12"}
            buttonLabel="Next"
            buttonLabelClasses="!text-lg"
            buttonEffect={"filled"}
            buttonHasLink={false}
            buttonFunction={validateAndNavigate}
          />
        </div>
      </div>
    </>
  );
};

export default ShippingAddress;
