
import React from "react";
import Tabs from "../elements/Tabs";
import Description from "./tabContent/Description";


const SingleProductDescription = ({ details }) => {
  const tabData = [
    {
      _id: 1,
      name: "Description",
      component: <Description details={details} />,
    },
    {
      _id: 2,
      name: "Info",
      component: <></>,
    },
  ];
  return (
    <div className="pt-5 sm:pt-10 lg:pt-20 relative">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="relative">
          <div className="capitalize text-black text-base lg:text-lg xl:text-xl 2xl:text-2xl font-bold pl-3 border-l-2 border-sv-blue mb-4 sm:mb-8 after:border-b-4 after:border-org-theme after:right-0 after:content-[''] after:absolute after:top-4 after:w-[0] md:after:w-[40%] lg:after:w-[56%] 2xl:after:w-[60%] 3xl:after:w-[80%]">
            Product Description
          </div>
        </div>
        <div className="relative w-full">
          <div className="flex gap-3 justify-between">
            <div className="relative w-full">
              <Tabs tabs={tabData} divClass={"w-full"} textClasses={"font-semibold"} activeLabelClass={"!text-white !font-bold "} inActiveLabelClass={"!text-black !font-normal"} />
            </div>
            <div className="relative w-1/2">
              <div className="relative w-full flex justify-center lg:justify-end">
                <div className="w-full relative lg:aspect-auto xl:aspect-[3/2] max-w-full lg:max-w-xl">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/VyK0jjMmojU?si=Ut0POaVcDuGTvdWh"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductDescription;
