import React from "react";

const DefaultFooter = () => {
  return (
    <footer className="footer_widgets">
      <div className="footer_top py-10">
        <div className="container mx-auto px-0">
          <div className="flex flex-wrap gap-5">
            <div className="lg:w-1/3 md:w-1/2 w-full">
              <div className="widgets_container newsletter">
                <a href="#">
                  <img
                    src="assets/img/unicorn.svg"
                    alt=""
                    className="w-1/2 mb-4"
                  />
                </a>
                <div className="newsletter-content">
                  <p>
                    Exceptional quality. Ethical factories. Sign up to enjoy
                    free U.S. shipping and returns on your first order.
                  </p>
                  <p>
                    Exceptional quality. Ethical factories. Sign up to enjoy
                    free U.S. shipping and returns on your first order.
                  </p>
                </div>
                <div className="cardarea mt-4">
                  <b>We accept:</b>{" "}
                  <img
                    src="assets/img/payment-methods.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="lg:w-1/6 md:w-1/3 w-1/2">
              <div className="widgets_container">
                <h3 className="text-xl mb-4">Information</h3>
                <div className="footer_menu">
                  <ul>
                    <li>
                      <a href="about.html" className="block py-1">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#" className="block py-1">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a href="#" className="block py-1">
                        Delivery Information
                      </a>
                    </li>
                    <li>
                      <a href="contact.html" className="block py-1">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="#" className="block py-1">
                        Refund and Returns Policy
                      </a>
                    </li>
                    <li>
                      <a href="#" className="block py-1">
                        Exchange & Return Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="lg:w-1/6 md:w-1/3 w-1/2">
              <div className="widgets_container">
                <h3 className="text-xl mb-4">Extras</h3>
                <div className="footer_menu">
                  <ul>
                    <li>
                      <a href="wishlist.html" className="block py-1">
                        Wishlist
                      </a>
                    </li>
                    <li>
                      <a href="login.html" className="block py-1">
                        Login
                      </a>
                    </li>
                    <li>
                      <a href="product-listing.html" className="block py-1">
                        Order History
                      </a>
                    </li>
                    <li>
                      <a href="product-listing.html" className="block py-1">
                        Gift Certificates
                      </a>
                    </li>
                    <li>
                      <a href="product-listing.html" className="block py-1">
                        Anime
                      </a>
                    </li>
                    <li>
                      <a href="product-listing.html" className="block py-1">
                        Bengali Series
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="lg:w-1/6 md:w-1/2 w-full">
              <div className="widgets_container contact_us">
                <h3 className="text-xl mb-4">Contact Us</h3>
                <div className="footer_contact">
                  <p>
                    Address: West Bengal, Howrah, P. S - Domjur Prosastha sardar
                    para, satbigha mat, 711302
                  </p>
                  <p>
                    Phone:{" "}
                    <a href="tel:01234567890" className="text-blue-500">
                      01234567890
                    </a>{" "}
                  </p>
                  <p>
                    Email:{" "}
                    <a href="mailto:demo@example.com" className="text-blue-500">
                      demo@example.com
                    </a>
                  </p>
                  <ul className="flex space-x-4 mt-4">
                    <li>
                      <a href="#" title="facebook" className="text-blue-600">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="instagram" className="text-pink-600">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="youtube" className="text-red-600">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom py-4 bg-gray-800 text-white">
        <div className="container mx-auto px-0">
          <div className="flex flex-wrap">
            <div className="lg:w-1/2 w-full">
              <div className="copyright_area">
                <p>
                  {" "}
                  &copy; 2024 <strong>Unicorn</strong> All Rights Reserved
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 w-full">
              <div className="footer_custom_links">
                <ul className="flex space-x-4 justify-end">
                  <li>
                    <a href="#" className="hover:underline">
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DefaultFooter;
