import React, { useState } from "react";
import { classNames } from "../../helpers/classNames";

const Radio = ({
  onChange = () => { },
  divClass,
  radioName,
  radioInputClass,
  isChecked,
  radioLabel,
  radioboxClass,
  buttonIcon,
  radioLableClass,
  value,
  checked,
  background,
  isDisabled,
  ...props
}) => {
  return (
    <div
      className={classNames("relative  flex flex-col items-center", divClass)}
    >
      <input
        type="radio"
        name={radioName}
        className={classNames(
          "peer absolute top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer",
          radioInputClass
        )}
        value={value}
        defaultChecked={isChecked}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            checked: e.target.checked || false,
            name: e.target.name,
          })
        }
        checked={checked}
        disabled={isDisabled}
      />
      <div
        style={{ background }}
        className={classNames(
          "w-5 h-5 flex-shrink-0 border border-slate-300 rounded-full flex items-center !justify-center text-[8px] bg-white text-black/0 peer-checked:bg-sv-blue peer-checked:border-sv-blue peer-checked:text-white",
          radioboxClass
        )}
      >
        <i className={classNames("fa-solid fa-fw fa-circle", buttonIcon)}></i>
      </div>
      {radioLabel && (
        <div
          className={classNames(
            "text-base font-medium text-slate-600 w-full ml-3",
            radioLableClass
          )}
        >
          {radioLabel}
        </div>
      )}
    </div>
  );
};

export default Radio;
