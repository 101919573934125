import React from 'react'
import Tabs from '../../components/elements/Tabs';

const MyOrders = () => {

  const tabData = [
    {
      _id: 1,
      name: "Processing",
      // component: <Active />,
    },
    {
      _id: 2,
      name: "Shipped",
      // component: <Shipped />,
    },
    {
      _id: 3,
      name: "Delivered",
      // component: <Completed />,
    },
    {
      _id: 4,
      name: "Canceled",
      // component: <Cancelled />,
    },
  ];

  return (
    <div>
       <div className="capitalize relative text-cape-cod text-2xl border-l border-sv-blue pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[85%] after:z-10">
            My Orders
          </div>
          <Tabs
              tabs={tabData}
              divClass={"w-full sm:justify-between"}
              activeClass={"sm:!px-10"}
            />
    </div>
  )
}

export default MyOrders