import React from "react";
import banner from "../../assets/img/bg/banner3.jpg";

const BannerSection = () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat lg:mx-[90px] mx-auto"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="min-h-[80vh] flex items-center">
        <div className="container mx-auto px-4">
          <div className="text-left text-semibold md:ml-[50px] lg:ml-[70px]">
            <h1 className="text-org-black text-2xl md:text-4xl lg:text-5xl mb-4">
              Game Of Thrones Jaime
              <br /> Lannister <br />
              Themed Sneakers
            </h1>
            <p className="inline-block px-6 py-3 mt-4 text-lg font-medium text-org-black underline hover:text-org-theme">
              Discover Now
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
