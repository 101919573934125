import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import ProductCardSample from "../product/ProductCardSample";
import slide1 from "../../assets/img/product/product5.jpg";
import banner from "../../assets/img/bg/banner2.jpg";

const RelatedProduct = () => {
  const products = [
    {
      _id: 1,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 2,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 3,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 4,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 5,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 6,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
    {
      _id: 7,
      image: "https://via.placeholder.com/150",
      hoverImage: "https://via.placeholder.com/150/0000FF/808080",
      title: "Sample Product",
      price: "19.99",
    },
  ];

  return (
    <div className="relative px-6 py-10 my-8 ">
      <div className="w-full text-center">
        <h2 className="text-4xl font-bold text-org-black">Related Product</h2>
        <p className="text-gray-600 mt-2">
          Contemporary, minimal and modern designs embody the Lavish Alice
          handwriting
        </p>
      </div>
      <div className="mt-3">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={true}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
          }}
          className="trending-products-swiper"
        >
          {products.map((product) => (
            <SwiperSlide key={product?._id} className="">
              <ProductCardSample
                imgSrc={slide1}
                title="Product Title"
                price="Rs70.00"
                productUrl="/products-details"
                cartUrl="cart.html"
                wishlistUrl="wishlist.html"
                hoverImageSrc={banner}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default RelatedProduct;
