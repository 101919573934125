import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../util/apiUtils";

const ProtectedRoute = (props) => {
  // const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");

  useEffect(() => {
    const checkData = async () => {
      try {
        const res = await apiRequest(
          "post",
          "/verify-token",
          {}
        );
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        } else {
          setAuth(true);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Optionally handle different error statuses
        if (error?.response?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        } else {
          // Handle other types of errors (e.g., network issues)
          console.error("Unexpected error:", error);
          localStorage.removeItem("auth_token");
          navigate("/");
          setAuth(false);
        }
      }
    };

    if (!token_) {
      setIsLoader(false);
      navigate("/");
    } else {
      checkData();
    }
  }, [token_]);

  return <>{auth && props.children}</>;
};
export default ProtectedRoute;
