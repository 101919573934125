import React from "react";
import OrderMenu from "../pages/profile/OrderMenu";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import Logo from "../assets/images/logo.png";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";

const UserLayout = ({ children, settingsaData }) => {
  const details = {
    _id: "668d2bc7a0b0979ba2407c38",
    fullname: {
      firstName: "Symlink",
      lastName: "Demo",
      _id: "668d2bc7a0b0979ba2407c39",
    },
    email: "symlinktestmail@gmail.com",
    emailVerified: true,
    emailVerifiedAt: "2024-07-09T12:59:38.532Z",
    phone: null,
    ISDCode: null,
    isStudentMilitaryDiscountEligible: false,
    roleCode: "user",
    referralCode: "Z14XXaSDWQ",
    gender: null,
    dateOfBirth: null,
    status: "active",
    ordering: 3,
    address: [
      {
        address1: "xjavxas",
        address2: "dasda",
        landmark: null,
        city: "sdas",
        state: "dasd",
        country: "asda",
        zipcode: 700068,
        type: "point",
        coordinates: null,
        altPhone: "",
        phone: "9874568524",
        isDefault: true,
        _id: "668e2df72c39c1898f1888d5",
      },
    ],
    createdAt: "2024-07-09T12:23:35.018Z",
    planId: "65eedbbf408191dd09909c17",
    subscription: true,
    isSignupFeesEligible: false,
    endDate: "2024-08-18T07:07:11.611Z",
    isMemberDiscountEligible: true,
  };

  const menuData = {
    logo: Logo,
    menu: [
      { _id: 1, name: "Home", link: "banner" },
      { _id: 2, name: "About Us", link: "about" },
      { _id: 3, name: "FAQ's", link: "faq" },
      { _id: 4, name: "Contact Us", link: "contact" },
    ],
    appDownload: {
      menu: [
        { _id: 1, name: "Play Store", link: "/", image: PlayStore },
        { _id: 2, name: "App Store", link: "/", image: AppStore },
      ],
    },
  };

  return (
    <div>
      <DefaultHeader data={menuData} />
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          {/* <Breadcrumb data={breadcrumbMainItems} /> */}
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={details?.fullname?.firstName} />
            </div>
            <div className="w-full lg:w-4/5">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
