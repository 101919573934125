import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import ProductCardSample from "../product/ProductCardSample";
import slide1 from "../../assets/img/product/product5.jpg";
import banner11 from "../../assets/img/bg/banner11.jpg";
import banner12 from "../../assets/img/bg/banner12.jpg";
const OfferDiscountBanner = () => {
  return (
    <div className="relative px-6 py-10 my-8 ">
      <div className="w-full text-center">
        <h2 className="text-4xl font-bold text-org-black">
          Our Offer & Discount Products
        </h2>
        <p className="text-gray-600 mt-2">
          Contemporary, minimal and modern designs embody the Lavish Alice
          handwriting
        </p>
      </div>
      <div className=" relative mt-3">
      <div className="flex flex-wrap">
      <div className="w-full lg:w-1/2 p-2">
        <div className="relative">
          <a href="product-listing.html">
            <img src={banner11} alt="Creative Bengali Quotes T-shirt" className="w-full h-auto" />
          </a>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-left text-left  p-6">
            <h1 className="text-white text-2xl md:text-4xl font-bold mb-4">
              Creative Bengali<br /> Quotes T-shirt
            </h1>
            <a href="product-listing.html" className="text-white text-lg font-medium underline hover:text-org-theme">
              Discover Now
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 p-2">
        <div className="relative">
          <a href="product-listing.html">
            <img src={banner12} alt="Quoted Full Sleeve T-shirt" className="w-full h-auto" />
          </a>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-left text-left  p-6">
            <h1 className="text-white text-2xl md:text-4xl font-bold mb-4">
              Quoted <br /> Full Sleeve T-shirt
            </h1>
            <a href="product-listing.html" className="text-white text-lg font-medium underline hover:text-org-theme">
              Discover Now
            </a>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default OfferDiscountBanner;
