import React, { useCallback, useEffect, useMemo, useState } from "react";
import QuantityField from "../form/QuantityField";
import Button from "../form/Button";
import Radio from "../form/Radio";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const ProductItem = ({
  isVerient = null,
  data = {},
  combine = null,
  attribute = {},
  redirectByAttr = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [availableAttr, setAvailableAttr] = useState([]);

  useEffect(() => {
    setAvailableAttr(data?.data?.availableAttributes || []);
  }, [data]);

  const isAvailable = (availableAttr, attr, item) => {
    return availableAttr
      ?.find((elm) => elm?._id === attr?.attribute?._id)
      ?.items?.includes(item?._id);
  };

  console.log(data, "dataaa");

  return (
    <>
      {isVerient != null ? (
        <>
          <div>
            {" "}
            <div className="w-full relative">
              <h1 className="text-2xl md:text-3xl font-bold mb-4">
                {data?.data?.product?.name}
              </h1>
              <div className="flex items-center mb-4">
                <div className="flex text-org-theme">
                  {[...Array(5)].map((_, index) => (
                    <i key={index} className="fa fa-star"></i>
                  ))}
                </div>
                <span className="ml-4 text-gray-600">100 review</span>
                <span className="ml-2 text-blue-500 cursor-pointer">
                  Write a review
                </span>
              </div>
              <div className="line-through text-lg lg:text-base text-slate-400">
                Rs. {data?.data?.product?.variant?.regularPrice}
              </div>
              <div className="text-xl text-sv-red font-medium">
                Rs. {data?.data?.product?.variant?.salePrice}
              </div>
              <p className="mb-4">
                <div
                  className="text-sm lg:text-base !text-black"
                  dangerouslySetInnerHTML={{ __html: data?.data?.product?.description }}
                ></div>
              </p>
              {data?.data?.product?.type === "variant-product" &&
                data?.data?.product?.attributes.map((attr, i) => (
                  <div className="relative" key={i?.toString()}>
                    <div className="capitalize text-black font-bold text-lg mb-2">
                      {attr?.attribute?.name}
                    </div>
                    <div className="flex-wrap flex xl:flex-nowrap xl:flex items-center gap-2 lg:gap-2 mb-8">
                      {attr?.items?.map((item, index) => {
                        const available = isAvailable(
                          availableAttr,
                          attr,
                          item
                        );
                        // console.log(item, attr, "tttr");
                        return (
                          <div
                            key={index}
                            className={`relative group bg-white ${
                              !available ? "opacity-20" : ""
                            }`}
                          >
                            <div className="relative">
                              <Radio
                                key={index}
                                // isDisabled={!available}
                                radioName={attr?.attribute?.name}
                                value={item?._id}
                                radioLabel={!attr?.isColor && item?.name}
                                divClass={
                                  attr?.isColor
                                    ? "group"
                                    : "min-w-12 lg:min-w-16 rounded-md hover:!bg-slate-100"
                                }
                                radioLableClass={
                                  attr?.isColor
                                    ? ""
                                    : "text-sm lg:text-base text-center !m-0 py-1 text-black peer-checked:bg-white peer-checked:!border-sv-blue border border-slate-200 rounded-lg uppercase px-3"
                                }
                                radioboxClass={
                                  attr?.isColor
                                    ? "text-sm w-5 h-5 lg:w-8 lg:h-8 rounded-full peer-checked:!border-none group-hover:ring-2 group-hover:ring-offset-2 group-hover:ring-gray-300 transition-all duration-200 peer-checked:ring-2 peer-checked:ring-offset-2 peer-checked:ring-gray-300"
                                    : "!h-3 rounded-md absolute w-full h-full z-[-1] text-[0px] !border-none"
                                }
                                buttonIcon={
                                  attr?.isColor && "fa-solid fa-fw fa-check"
                                }
                                radioInputClass={attr?.isColor ? "w-5 h-5" : ""}
                                background={attr?.isColor && item?.code}
                                onChange={(e) => {
                                  if (!available) {
                                    toast.error("Out of stock");
                                    return;
                                  }
                                  redirectByAttr({
                                    [e?.name]: e.value,
                                  });
                                }}
                                // checked={
                                //   router.query[attr?.attribute?.name] === item?._id
                                // }
                                checked={
                                  searchParams.get(attr?.attribute?.name) ===
                                  item?._id
                                }
                              />
                              {attr?.isColor && (
                                <div className="absolute hidden group-hover:block bg-white border border-sv-blue text-xs rounded py-1 px-2 z-10 top-full mt-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap min-w-max capitalize">
                                  {item?.name}
                                  <div className="absolute left-1/2 transform -translate-x-1/2 w-0 h-0 border-b-8 bg-white border border-sv-blue border-r-8 border-r-transparent border-l-8 border-l-transparent bottom-full"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}

              <div className="flex-wrap flex lg:flex items-end gap-1">
                <div className="relative">
                  <QuantityField
                    label={"Quantity"}
                    labelClasses={"!font-bold !text-sm lg:!text-lg"}
                    buttonClasses={
                      "!text-black border-t border-b border-r !rounded-0 !w-8 lg:!w-8 !h-8 lg:!h-7 !min-w-6 sm:!min-w-8 lg:!min-w-8 text-sm"
                    }
                    buttonIconPosition={"left"}
                    buttonIcon={"fa-regular fa-plus"}
                    inputClasses={
                      "relative z-[1] w-12 h-16 lg:h-14 min-w-8 sm:min-w-8 lg:min-w-16 rounded-l-md !text-black border ml-1 border-slate-300 bg-slate-100"
                    }
                    // onChange={(val) => setQuantity(Number(val))}
                  />
                </div>
                <div className="relative flex gap-3 items-end">
                  <Button
                    buttonLabel={"add to cart"}
                    buttonLabelClasses={
                      "capitalize !font-normal xxs:!text-[11px] xs:!text-xs lg:!text-base xl:text-lg !ml-1 xs:!ml-2"
                    }
                    buttonHasLink={false}
                    // buttonLink={"/cart"}
                    buttonClasses={
                      "!bg-sv-blue border-white !px-3 md:!px-5 lg:!px-5 xl:!px-5 2xl:px-8 !h-8 lg:!h-12 hover:shadow-lg hover:!bg-black transition-all duration-300 !text-[10px]"
                    }
                    buttonIconPosition={"left"}
                    //   isDisable={combine === false && combine != null}
                    buttonIcon={"fa fa-shopping-basket"}
                    //   buttonFunction={() => addToCart(data?.data)}
                  />

                  <div className="relative">
                    <Button
                      buttonClasses={
                        "!bg-sv-blue border-white !px-3 md:!px-5 lg:!px-5 xl:!px-5 2xl:px-8 !h-8 lg:!h-12 hover:shadow-lg hover:!bg-black transition-all duration-300 !text-[10px]"
                      }
                      buttonLabel={"Buy Now"}
                      buttonIcon={"fa-solid fa-cart-shopping"}
                      buttonIconPosition={"left"}
                      buttonLabelClasses={
                        "capitalize !font-normal xxs:!text-[11px] xs:!text-xs lg:!text-base xl:text-lg !ml-1 xs:!ml-2"
                      }
                      buttonHasLink={false}
                      //   buttonFunction={() => setMembershipModal(true)}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Share on:</h3>
                <ul className="flex space-x-4">
                  <li>
                    <a href="#" className="text-blue-600 hover:text-blue-800">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-pink-600 hover:text-pink-800">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <h1>"Loadinggggg"</h1>
        </>
      )}
    </>
  );
};

export default ProductItem;
