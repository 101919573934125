import { configureStore } from "@reduxjs/toolkit";
import detailsProfileSlice from "./slice/detailsProfileSlice";
import cartSlice from "./slice/cartSlice";
import authSlice from "./slice/authSlice";
import profileSlice from "./slice/profileSlice";

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    cart: cartSlice,
    auth: authSlice,
  },
});
