import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Tabs = ({
  divClass,
  tabs,
  navTabClass,
  activeClass,
  inActiveLabelClass,
  activeLabelClass,
  textClasses,
  setData = () => { },
  type,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState({});
  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if (filteredTab) {
        activeTab = [...activeTab.filter((actab) => actab !== filteredTab._id), tab?._id];
      } else {
        activeTab = [...activeTab, tab?._id];
      }
      setSelectedTab(tab);
      setData(tab);
    }, 100);
  };

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs[0]);
      setData(tabs[0]);
    }
  }, [tabs.length]);

  // useEffect(() => {
  //   if (type === "sizeInfo") {
  //     // localStorage.setItem("sizeInfo", selectedTab.name);
  //   }
  // }, [type, selectedTab?._id]);

  return (
    <>
      <div className="relative">
        <div className={classNames("flex overflow-auto", navTabClass)}>
          <nav className={classNames("flex mb-4 space-x-4 border-b border-slate-200 ", divClass)}>
            {tabs.map((item, index) => (
              <button
                type="button"
                className={classNames(
                  "relative px-2 py-2 transition-all duration-200 border-b-2 rounded-t-xl",
                  item._id === selectedTab?._id ? "border-org-theme bg-org-theme" : "border-transparent bg-transparent",
                  activeClass
                )}
                key={index}
                onClick={() => handleTabSelection(item)}>
                <div
                  className={classNames(
                    "whitespace-nowrap text-sm lg:text-lg transition-all duration-200", textClasses,
                    item._id === selectedTab?._id
                      ? classNames("text-stone-700", activeLabelClass)
                      : classNames("text-concord", inActiveLabelClass)
                  )}>
                  {item.name}
                </div>
              </button>
            ))}
          </nav>
        </div>
        <div className="relative">{selectedTab?.component}</div>
      </div>
    </>
  );
};

export default Tabs;
