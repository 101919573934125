import React, { useEffect, useState } from "react";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import {
  changePassword,
  getProfileDetails,
} from "../../services/profilrService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderMenu from "./OrderMenu";

const ProfileDetails = () => {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({});
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getProfileDetails().then((res) => {
      console.log(res, "res");
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    setFormData({
      name: data?.name,
      phone: data?.phone,
    });
  }, [data]);

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleUpdatePassword = () => {
    const { oldPassword, newPassword, confirmPassword } = passwordData;
    let validationErrors = {};

    if (!oldPassword) {
      validationErrors.oldPassword = "Old password is required";
    }

    if (!validatePassword(newPassword)) {
      validationErrors.newPassword =
        "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    if (newPassword !== confirmPassword) {
      validationErrors.confirmPassword =
        "New password and confirm password do not match.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error("Please fix the errors in the form.");
      return;
    }

    // Proceed with password update logic
    console.log("Passwords are valid. Proceed with update.");
    changePassword({ ...passwordData }).then((res) => {
      console.log(res, "rrrrr password");
    });
  };


  const details = {
    _id: "668d2bc7a0b0979ba2407c38",
    fullname: {
      firstName: "Symlink",
      lastName: "Demo",
      _id: "668d2bc7a0b0979ba2407c39",
    },
    email: "symlinktestmail@gmail.com",
    emailVerified: true,
    emailVerifiedAt: "2024-07-09T12:59:38.532Z",
    phone: null,
    ISDCode: null,
    isStudentMilitaryDiscountEligible: false,
    roleCode: "user",
    referralCode: "Z14XXaSDWQ",
    gender: null,
    dateOfBirth: null,
    status: "active",
    ordering: 3,
    address: [
      {
        address1: "xjavxas",
        address2: "dasda",
        landmark: null,
        city: "sdas",
        state: "dasd",
        country: "asda",
        zipcode: 700068,
        type: "point",
        coordinates: null,
        altPhone: "",
        phone: "9874568524",
        isDefault: true,
        _id: "668e2df72c39c1898f1888d5",
      },
    ],
    createdAt: "2024-07-09T12:23:35.018Z",
    planId: "65eedbbf408191dd09909c17",
    subscription: true,
    isSignupFeesEligible: false,
    endDate: "2024-08-18T07:07:11.611Z",
    isMemberDiscountEligible: true,
  };

  return (
    <div>
      <div className="relative py-5 bg-sv-blue/5">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          {/* <Breadcrumb data={breadcrumbMainItems} /> */}
          <div className="block lg:flex justify-between gap-10 py-0 sm:py-4 lg:py-10">
            <div className="w-full lg:w-1/5">
              <OrderMenu userName={details?.fullname?.firstName} />
            </div>
            <div className="w-full lg:w-4/5">
              <div className="grid grid-cols-12 sm:grid-cols-6 gap-x-1 sm:gap-x-5 gap-y-5">
                <div className="relative col-span-12 sm:col-span-3">
                  <Input
                    label={"Name*"}
                    inputType={"text"}
                    inputName={"name"}
                    value={formData?.name}
                    labelClasses={"!font-normal capitalize !text-base"}
                    inputClasses={
                      "h-12 !border-none !bg-gray-100 !font-normal "
                    }
                    inputPlaceholder="Enter Your Name"
                    onChange={handleFormData}
                  />
                </div>

                <div className="relative col-span-12 sm:col-span-3">
                  <Input
                    label={"Phone*"}
                    inputType={"text"}
                    inputName={"phone"}
                    value={formData?.phone}
                    labelClasses={"!font-normal capitalize !text-base"}
                    inputClasses={
                      "h-12 !border-none !bg-gray-100 !font-normal "
                    }
                    inputPlaceholder="Phone"
                    onChange={handleFormData}
                  />
                </div>

                <div className="relative col-span-12 sm:col-span-3">
                  <div className="">
                    <div className="mb-2">Email: </div>
                    <div className="h-12 flex items-center justify-between pl-3 rounded-lg !text-base !bg-gray-100 !font-normal">
                      <span>{data && data?.email}</span>
                      {/* <Button
                buttonLabel={"Update email"}
                buttonLabelClasses={"!opacity-0 xl:!opacity-100"}
                buttonIcon={"fa-solid fa-pencil"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!bg-sv-blue !border-0 !w-7 xl:!w-auto !h-8 !mr-2 !rounded-r-lg  mt-1 !rounded hover:!bg-black transition-all duration-300"
                }
                buttonHasLink={false}
              /> */}
                    </div>
                  </div>
                </div>

                <div className="relative col-span-12 sm:col-span-6">
                  <div className="flex items-center gap-5">
                    <Button
                      buttonLabel={"Update"}
                      buttonLabelClasses={"!text-base !font-normal !capitalize"}
                      buttonClasses={
                        "!bg-sv-blue !text-white !border-0 !h-10 !px-4 hover:!bg-black"
                      }
                      buttonIcon={"fa-fw fa-solid fa-grip-dots"}
                      buttonIconPosition={"left"}
                      buttonType={"submit"}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-x-1 sm:gap-x-5 py-6 space-y-5 sm:space-y-0">
                <div className="relative col-span-12 sm:col-span-4">
                  <Input
                    label={"Old Password"}
                    inputType={"password"}
                    inputName={"oldPassword"}
                    value={passwordData.oldPassword}
                    labelClasses={"!font-normal capitalize !text-base"}
                    inputClasses={
                      "h-12 !border-none !bg-gray-100 !font-normal "
                    }
                    inputPlaceholder="Old Password"
                    onChange={handlePasswordChange}
                    // errorType={!passwordData.oldPassword && "danger"}
                    // errorText={"Old password is required!"}
                  />
                  {errors.oldPassword && (
                    <p className="text-red-500 text-sm">{errors.oldPassword}</p>
                  )}
                </div>
                <div className="relative col-span-12 sm:col-span-4">
                  <Input
                    label={"New Password"}
                    inputType={"password"}
                    inputName={"newPassword"}
                    value={passwordData.newPassword}
                    labelClasses={"!font-normal capitalize !text-base"}
                    inputClasses={
                      "h-12 !border-none !bg-gray-100 !font-normal "
                    }
                    inputPlaceholder="New Password"
                    onChange={handlePasswordChange}
                    errorType={
                      passwordData.newPassword &&
                      !validatePassword(passwordData.newPassword) &&
                      "danger"
                    }
                    errorText={
                      "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character."
                    }
                  />
                  {/* {errors.newPassword && (
            <p className="text-red-500 text-sm">{errors.newPassword}</p>
          )} */}
                </div>
                <div className="relative col-span-12 sm:col-span-4">
                  <Input
                    label={"Confirm Password"}
                    inputType={"password"}
                    inputName={"confirmPassword"}
                    value={passwordData.confirmPassword}
                    labelClasses={"!font-normal capitalize !text-base"}
                    inputClasses={
                      "h-12 !border-none !bg-gray-100 !font-normal "
                    }
                    inputPlaceholder="Confirm Password"
                    onChange={handlePasswordChange}
                    errorType={
                      validatePassword(passwordData.newPassword) &&
                      passwordData.confirmPassword &&
                      passwordData.newPassword !==
                        passwordData.confirmPassword &&
                      "danger"
                    }
                    errorText={
                      "New password and confirm password do not match."
                    }
                  />
                  {/* {errors.confirmPassword && (
            <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
          )} */}
                </div>
                <div className="relative col-span-12 sm:col-span-6 py-5">
                  <div className="flex items-center gap-5">
                    <Button
                      buttonLabel={"Update Password"}
                      buttonLabelClasses={
                        "xxs:!text-xs xs:!text-base !font-normal !capitalize"
                      }
                      buttonClasses={
                        "!bg-sv-blue !border-0 !h-10 !px-4 hover:!bg-black transition-all duration-300"
                      }
                      buttonType={"button"}
                      buttonFunction={handleUpdatePassword}
                    />
                    <Button
                      buttonLabel={"Cancel"}
                      buttonLabelClasses={
                        "!text-base !font-semibold !capitalize !text-concord group-hover/button:!text-white transition-all duration-300"
                      }
                      buttonClasses={
                        "!bg-slate-200 !border-slate-200 !h-10 !px-4 group/button hover:!bg-black transition-all duration-300"
                      }
                      // buttonFunction={handleCancel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
