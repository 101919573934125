import React from 'react'
import { classNames } from '../../helpers/classNames'
import ProfileImage from './ProfileImage';
import { Link } from 'react-router-dom';

const OrderMenu = () => {

    const menuData = [
        {
          _id: 1,
          name: "My Info",
          menuIcon: "fa-regular fa-award",
          link: "/profile",
        },
    
        {
          _id: 2,
          name: "My Orders",
          menuIcon: "fa-regular fa-bag-shopping",
          link: "/orders",
        },
        {
          _id: 3,
          name: "Wishlist",
          menuIcon: "fa-regular fa-heart",
          link: "/wishlist",
        },
        {
          _id: 4,
          name: "My Address",
          menuIcon: "fa-regular fa-address-card",
          link: "/myaddress",
        },
        // {
        //   _id: 5,
        //   name: "My Giftcards",
        //   menuIcon: "fa-regular fa-gift-card",
        //   link: "/my-giftcards",
        // },
        // {
        //   _id: 6,
        //   name: "My Wallet",
        //   menuIcon: "fa-regular fa-wallet",
        //   link: "/my-wallet",
        // },
        // {
        //   _id: 6,
        //   name: "Referrals",
        //   menuIcon: "fa-regular fa-user",
        //   link: "/referrals",
        // },
        // {
        //   _id: 7,
        //   name: "My Subscription",
        //   menuIcon: "fa-regular fa-badge-dollar",
        //   link: "/mysubscription",
        // },
        {
          _id: 8,
          name: "Logout",
          menuIcon: "fa-regular fa-right-from-bracket",
        //   onClick: () => {
        //     setDeleteModal({
        //       isOpen: true,
        //     });
        //   },
        },
      ];
  return (
    <div>
        <div className="capitalize text-black text-4xl font-bold border-l-2 border-transparent mb-2 block justify-center lg:justify-normal p-5 bg-gradient-to-b from-[#f4f7fe] to-white">
        <ProfileImage />
        <div className="capitalize text-black text-base lg:text-lg xl:text-2xl font-bold pl-3 border-0 lg:border-l-2 border-sv-blue mb-2 text-center lg:text-left mx-auto w-auto sm:w-[18%] lg:w-[100%] mt-3">
          Hello Shakil
        </div>
        <div className="text-concord text-sm text-center lg:text-left">
          Welcome to your Account
        </div>
      </div>

      <div className="relative mt-5">
        {menuData?.length > 0 && (
          <div className="block gap-14 space-y-2 relative">
            {menuData?.map((item) => (
              <div key={item?._id} className="group">
                {item?.link ? (
                  <Link
                    to={item?.link}
                    className={classNames(
                      "w-full pl-3 py-1 flex space-x-3 text-center text-lg transition-all duration-200 border-l border-slate-50 rounded-r-lg font-bold group",
                    //   router?.pathname === item?.link
                    //     ? "text-sv-blue border-sv-blue hover:bg-slate-100 bg-gradient-to-l from-[#f4f7fe] to-white"
                        // : 
                        "text-black hover:bg-slate-100 hover:border-l hover:border-sv-blue font-normal text-sm bg-gradient-to-l from-[#f4f7fe] to-[#e5edff]"
                    )}
                  >
                    <span className="ml-1 text-lg group-hover:text-sv-blue">
                      <i className={classNames("fa-fw", item?.menuIcon)}></i>
                    </span>
                    <span className="inline-block text-lg">{item?.name}</span>
                  </Link>
                ) : (
                  <div
                    onClick={item?.onClick}
                    className={classNames(
                      "w-full pl-3 py-1 flex space-x-3 text-center text-lg transition-all duration-200 border-l border-slate-50 rounded-r-lg font-normal group text-black hover:bg-slate-100 hover:border-l hover:border-sv-blue  bg-gradient-to-l from-[#f4f7fe] to-[#e5edff] cursor-pointer"
                    )}
                  >
                    <span className="ml-1 text-lg">
                      <i className={classNames("fa-fw", item?.menuIcon)}></i>
                    </span>
                    <span className="inline-block text-lg">{item?.name}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderMenu