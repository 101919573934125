import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCartList } from "../../services/cartService";

const initialState = {
  cartData: {
    isLoaded: true,
    data: [],
  },
  orderId: 0 || "",
  total: 0,
};

export const getCartListApi = createAsyncThunk(
  "cart/getCartListApi",
  async (data, { getState }) => {
    try {
      const state = getState();

      const response = await getCartList({
        ...data,
      });

      return { data: response.docs };
    } catch (error) {
      throw error;
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getCartData: (state, action) => {
      state.cartData.data = action.payload;
    },
    getTotal: (state, action) => {
      state.total = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartListApi.pending, (state, action) => {
        state.cartData.isLoaded = false;
      })
      .addCase(getCartListApi.fulfilled, (state, action) => {
        state.cartData.isLoaded = true;
        state.cartData.data = action.payload?.data;
      })
      .addCase(getCartListApi.rejected, (state, action) => {
        state.cartData.isLoaded = true;
      });
  },
});

export const { getCartData, getTotal, setOrderId } = cartSlice?.actions;
export default cartSlice?.reducer;
