import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";

import Home from "./pages/Home";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import InnerLayout from "./layouts/InnerLayout";
import ProductList from "./components/product/ProductList";
import SingleProduct from "./components/product/SingleProduct";
import ProductDetails from "./pages/product/ProductDetails";
import Cart from "./pages/Cart";
import Login from "./pages/Login";
import ProfileDetails from "./pages/profile/ProfileDetails";
import UserLayout from "./layouts/UserLayout";
import MyOrders from "./pages/profile/MyOrders";
import WishList from "./pages/profile/WishList";
import MyAddress from "./pages/profile/MyAddress";
import ProtectedRoute from "./routes/ProtectedRoute";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* Front Page Route */}
          <Route
            path="/login"
            // element={
            //   <FreeAuthRoute title={"Login"}>
            //     {/* <DefaultLayout isMenuData={true} /> */}
            //   </FreeAuthRoute>
            // }
          >
            <Route
              path="/login"
              exact
              element={
                <FreeAuthRoute title={"Login"}>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/" element={<DefaultLayout isMenuData={true} />}>
            <Route path="/" exact element={<Home />} />
          </Route>
          <Route
            path="/products"
            element={<DefaultLayout isMenuData={false} />}
          >
            <Route path="/products" exact element={<Product />} />
          </Route>
          <Route
            path="/products-details/:slug"
            element={<DefaultLayout isMenuData={false} />}
          >
            <Route
              path="/products-details/:slug"
              exact
              element={<ProductDetails />}
            />
          </Route>
          <Route path="/cart" element={<DefaultLayout isMenuData={false} />}>
            <Route path="/cart" exact element={<Cart />} />
          </Route>

          <Route
            path="/profile"
            element={<DefaultLayout settingsaData={null} />}
          >
            <Route
              path="/profile"
              exact
              element={
                <ProtectedRoute title={"profile"}>
                  <ProfileDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/orders" element={<UserLayout settingsaData={null} />}>
            <Route path="/orders" exact element={<MyOrders />} />
          </Route>
          <Route path="/wishlist" element={<UserLayout settingsaData={null} />}>
            <Route path="/wishlist" exact element={<WishList />} />
          </Route>

          <Route
            path="/myaddress"
            element={<UserLayout settingsaData={null} />}
          >
            <Route path="/myaddress" exact element={<MyAddress />} />
          </Route>

          <Route
            path="/checkout"
            element={<DefaultLayout settingsaData={null} />}
          >
            <Route
              path="/checkout"
              exact
              element={
                // <ProtectedRoute title={"checkout"}>
                <Checkout />
                // </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
