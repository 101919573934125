import React from "react";

const Description = () => {
  return (
    <div>
      {" "}
      <div className="text-gray-800">
        <p>
          Fashion has been creating well-designed collections since 2010. The
          brand offers feminine designs delivering stylish separates and
          statement dresses which have since evolved into a full ready-to-wear
          collection in which every item is a vital part of a woman's wardrobe.
          The result? Cool, easy, chic looks with youthful elegance and
          unmistakable signature style. All the beautiful pieces are made in
          Italy and manufactured with the greatest attention. Now Fashion
          extends to a range of accessories including shoes, hats, belts, and
          more!
        </p>
      </div>
    </div>
  );
};

export default Description;
