import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import ProductList from "../components/product/ProductList";


const Product = () => {
  setTitle("Unicron Cloths");

  return (
    <>
      <ProductList />
    </>
  );
};

export default Product;
