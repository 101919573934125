import { apiRequest } from "../util/apiUtils";

export const productListData = async (params) => {
  try {
    const res = await apiRequest("get", "product/front/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getProductDetails = async (params) => {
  try {
    const res = await apiRequest("get", `product/front/${params?.slug}`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};
