import React from 'react';
import Cat_1_Image from "../../assets/img/trending-category.jpg";
import Cat_2_Image from "../../assets/img/trending-category2.jpg";
import Cat_3_Image from "../../assets/img/trending-category3.jpg";
import Cat_4_Image from "../../assets/img/trending-category4.jpg";

const Categories = () => {

    const data = [
        {
            _id: 1,
            image: Cat_1_Image,
            title: "Oversized T-shirts"
        },
        {
            _id: 2,
            image: Cat_2_Image,
            title: "Classic Fit T-shirts"
        },
        {
            _id: 3,
            image: Cat_3_Image,
            title: "Printed T-shirts"
        },
        {
            _id: 4,
            image: Cat_4_Image,
            title: "Girls Classic Fit T-shirts"
        },
    ];

    return (
        <div className='mb-4 lg:px-[110px]'>
            <div className='text-center mb-8'>
                <h1 className='text-3xl font-bold text-org-black'>Shop By Unicorn Category</h1>
                <p className='text-gray-600 mt-2'>Contemporary, minimal and modern designs embody the Lavish Alice handwriting</p>
            </div>
            <div className='mt-3 m-auto'>
                <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                    {
                        data.map((elm) => (
                            <div key={elm?._id} className='border cursor-pointer rounded-lg overflow-hidden'>
                                <div className='px-3 m-auto'>
                                    <img src={elm?.image} className="h-auto w-full object-cover" alt={elm?.title} />
                                </div>
                                <div className='border-t mt-2'/>
                                <h3 className='text-xl text-center py-2 text-org-black font-bold'>{elm?.title}</h3>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Categories;
