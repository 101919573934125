import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // profileData: [],
  profileData:{}
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      // console.log(action.payload, "action.payloadaction.payload");
      state.loading = false;
      state.profileData = action.payload;
    },
  },
});

export const { setProfileDetails } = profileSlice.actions;

export default profileSlice.reducer;

