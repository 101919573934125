import React from 'react'
import Input from '../../components/form/Input'
import Checkbox from '../../components/form/Checkbox'
import Button from '../../components/form/Button'

const MyAddress = () => {
  const list = {
    loading: false,
    data: [
      {
        address1: "xjavxas",
        address2: "dasda",
        landmark: null,
        city: "sdas",
        state: "dasd",
        country: "asda",
        zipcode: 700068,
        type: "point",
        coordinates: null,
        altPhone: "",
        phone: "9874568524",
        isDefault: true,
        _id: "668e2df72c39c1898f1888d5"
      }
    ],
    pageCount: 1
  };
  



  return (
    <div>
      
      <div className="capitalize relative text-cape-cod text-2xl border-l border-sv-blue pl-3 mb-8 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[82%] after:z-10">My Address</div>
          <div className="flex-col sm:flex-row flex">
            {list?.data?.map((item) => (
              <>
                <div className="relative bg-[#f6f6f6] rounded-xl py-2 px-4 space-y-3 mr-0 sm:mr-6 mb-4 sm:mb-0">
                  <div className="text-cape-cod text-xl font-medium border-b border-slate-200 pb-1">{item?.city}</div>
                  {/* <div className="text-cape-cod text-base">{item?.phone}</div> */}
                  <div className="text-cape-cod text-sm xl:leading-3">
                    {item?.address1}, {item?.address2}, {item?.city}
                  </div>
                  <div className="text-cape-cod text-sm leading-3">{item?.zipcode}</div>
                  <div className="flex items-center !mt-0 absolute top-[9px] right-3">
                    {/* {data.homes.map(
                  (
                    home,
                    index // Access each item directly
                  ) => ( */}
                    {item?.isDefault && (
                      <div
                        // key={index}
                        className="flex items-center justify-center capitalize px-3 py-1 bg-slate-300 rounded-full text-black text-xs font-medium">
                        {/* {home} */}
                        default
                      </div>
                    )}
                    {/* )
                )} */}
                  </div>
                  <div className="flex items-center gap-2 ">
                    <div className="relative">
                      <Button
                        buttonLabel={"remove"}
                        buttonLabelClasses={"!capitalize !text-white !text-xs !font-normal group-hover/button:!text-white !ml-1"}
                        buttonClasses={"!px-3 !border-none text !bg-sv-blue !rounded-full !py-1 !h-7 group/button hover:!bg-black transition-all duration-300"}
                        buttonIcon={"fa-regular fa-xmark"}
                        buttonIconPosition={"left"}
                        // buttonFunction={() => {
                        //   setDeleteModal({
                        //     isOpen: true,
                        //     apiEndpoint: "profile/delete-address",
                        //     method: "patch",
                        //     data: item,
                        //     payload: {
                        //       body: {
                        //         //body or queries
                        //         addressId: item._id,
                        //       },
                        //     },
                        //   });
                        // }}
                      />
                    </div>
                    <div className="relative">
                      <Button
                        buttonLabel={"edit"}
                        buttonLabelClasses={"!capitalize !text-white !text-sm !font-normal group-hover/button:!text-white !ml-1"}
                        buttonClasses={"!px-3 !border-none text !bg-sv-blue !rounded-full !py-1 !h-7 !text-xs  group/button hover:!bg-black transition-all duration-300"}
                        buttonIcon={"fa-solid fa-pen"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        // buttonFunction={() => {
                        //   setDetails(item);
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

      <div className="capitalize relative text-cape-cod text-2xl border-l border-sv-blue pl-3 my-10 font-bold after:border-b-2 after:border-slate-100 after:right-0 after:content[''] after:absolute after:top-4 xl:after:w-[75%] 2xl:after:w-[80%] 3xl:after:w-[82%] after:z-10">add address</div>

      <form>
            <div className="grid grid-cols-12 sm:grid-cols-6 gap-x-1 sm:gap-x-10 gap-y-5">
              <div className="relative col-span-12 sm:col-span-3">
                <Input
                  label={"Street Address"}
                  inputType={"text"}
                  labelClasses={"!font-normal capitalize !text-base"}
                  inputClasses={"h-12 !border-none !bg-gray-100 !font-normal "}
                  inputPlaceholder="House number and street name"
                  inputName={"address1"}
                  // register={register}
                  // errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter street address",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-3">
                <Input
                  label={"Apt, suite, unit"}
                  labelClasses={"!font-normal capitalize !text-base"}
                  inputClasses={"h-12 !border-none !bg-gray-100 !font-normal "}
                  inputPlaceholder="apartment, suite, unit, etc. (optional)"
                  inputName={"address2"}
                  // register={register}
                  // errors={errors}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-3">
                <Input
                  label={"Town / City"}
                  labelClasses={"!font-normal capitalize !text-base"}
                  inputClasses={"h-12 !border-none !bg-gray-100 !font-normal "}
                  inputPlaceholder="Town / City"
                  inputName={"city"}
                  // register={register}
                  // errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter town or city",
                  }}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-3">
                <Input
                  label={"State"}
                  labelClasses={"!font-normal capitalize !text-base"}
                  inputClasses={"h-12 !border-none !bg-gray-100 !font-normal "}
                  inputPlaceholder="State"
                  inputName={"state"}
                  // register={register}
                  // errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter state",
                  }}
                />
              </div>

              <div className="relative col-span-12 sm:col-span-3">
                <Input
                  label={"Postal Code"}
                  labelClasses={"!font-normal capitalize !text-base"}
                  inputClasses={"h-12 !border-none !bg-gray-100 !font-regular "}
                  inputPlaceholder="Postal Code"
                  inputType={"number"}
                  inputName={"zipcode"}
                  // register={register}
                  // errors={errors}
                  // onChange={(e) => {
                  //   const trimmedValue = e.target.value.trim().slice(0, 5);
                  //   const sanitizedValue = trimmedValue.replace(/\D/g, "");
                  //   setValue("zipcode", sanitizedValue);
                  // }}
                  rules={{
                    required: true,
                    message: "Please enter postal code",
                  }}
                />
              </div>

              <div className="relative col-span-12 sm:col-span-6">
                <Checkbox
                  checkboxLabel={"Set as default address"}
                  checkboxLableClass={"!text-lg !font-normal"}
                  // value={isDefault}
                  // onChange={(e) => setisDefault(e)}
                />
              </div>
              <div className="relative col-span-12 sm:col-span-6">
                <div className="flex items-center gap-5">
                  <Button
                    buttonLabel={"save"}
                    buttonLabelClasses={"!text-lg !font-normal !capitalize"}
                    buttonClasses={"!bg-sv-blue !h-12 !px-4 !border-0 hover:!bg-black transition-all duration-300"}
                    buttonType={"submit"}
                  />
                  <Button
                    buttonLabel={"cancel"}
                    buttonHasLink={false}
                    buttonLabelClasses={"!text-lg !font-bold !capitalize !text-concord group-hover/button:!text-white"}
                    buttonClasses={"!bg-slate-200 !border-slate-200 !h-12 !px-4 group/button hover:!bg-black transition-all duration-300"}
                    buttonFunction={() => {
                      // setDetails({});
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
    </div>
  )
}

export default MyAddress