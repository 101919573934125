import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constant/ApiConstant";

const builderQueryString = (queries = [], contentType = "application/json") => {
  let queryString = "";
  if (queries) {
    Object.keys(queries).forEach((query) => {
      if (Array.isArray(queries[query]) && queries[query].length) {
        queries[query].forEach((item) => {
          queries[`${query}[]`] = item;
        });

        delete queries[query];
      }
    });

    queryString = new URLSearchParams(queries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};
function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}
export const apiRequest = async (
  method = "put",
  url = "",
  payload = { body: null, queries: null },
  contentType = "application/json",
  options = { useCustomUrl: false, showToastMessage: false }
) => {
  let defaultPayload = { body: null, queries: null };
  payload = { ...payload, defaultPayload };

  let defaultOptions = { useCustomUrl: false, showToastMessage: false };
  options = { ...options, defaultOptions };

  let queryString = "";
  if (payload.queries != null) {
    queryString = builderQueryString(payload?.queries, contentType);
  }

  let accessToken = localStorage.getItem("auth_token");

  let headers = { "Content-Type": contentType };

  let payloadData = payload.body;

  // if (contentType.includes("form-data")) {
  //   payloadData = new FormData();

  //   Object.keys(payload.body).forEach((item) =>
  //     payloadData.append(item, payload.body[item])
  //   );
  // }
  if (contentType.includes("form-data")) {
    payloadData = jsonToFormData(payload.body);
  }

  const refreshToken = localStorage.getItem("refreshToken");

  try {
    if (accessToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    const request = axios.create({
      baseURL: options.useCustomUrl ? "" : API_BASE_URL,
      headers,
    });

    let res = await request[method.toLowerCase()](
      `${url}${queryString}`,
      method === "delete" ? { data: payloadData } : payloadData
    );

    if (res.status === 401 && res.data.tokenExpired && refreshToken) {
      const tokenRes = await request.post("refreshToken", {
        refreshToken,
      });

      if (tokenRes.data && tokenRes.data.accessToken) {
        request.defaults.headers = {
          ...headers,
          Authorization: `Bearer ${tokenRes.data.accessToken}`,
        };

        res = await request[method.toLowerCase()](
          `${url}${queryString}`,
          method === "delete" ? { data: payloadData } : payloadData
        );
      }
    }

    if (res.status) {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.success(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.success(res.data?.data?.message);
        }
      }
      return res;
    } else {
      if (options.showToastMessage) {
        if (res?.data?.message) {
          toast.error(res?.data?.message);
        }
        if (res?.data?.data?.message) {
          toast.error(res?.data?.data?.message);
        }
      }
      throw new Error(res?.message || res);
    }
  } catch (error) {
    if (options.showToastMessage) {
      toast.error(error?.response?.data);
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.data &&
      error?.response?.data?.tokenExpired &&
      refreshToken
    ) {
      const request = axios.create({
        baseURL: options.useCustomUrl ? "" : API_BASE_URL,
        headers,
      });

      const tokenRes = await request.post("refreshToken", {
        refreshToken,
      });

      if (tokenRes.data && tokenRes.data.accessToken) {
        localStorage.setItem("auth_token", tokenRes.data.accessToken);
        localStorage.setItem("refreshToken", tokenRes.data.refreshToken);

        request.defaults.headers = {
          ...headers,
          Authorization: `Bearer ${tokenRes.data.accessToken}`,
        };

        const res = await request[method.toLowerCase()](
          `${url}${queryString}`,
          method === "delete" ? { data: payloadData } : payloadData
        );

        if (res.status) {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.success(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.success(res.data?.data?.message);
            }
          }
          return res;
        } else {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.error(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.error(res.data?.data?.message);
            }
          }
          throw new Error(res?.message || res);
        }
      }
    }
    throw error.response;
  }
};

// import axios from "axios";
// import { API_BASE_URL } from "../constant/ApiConstant";

// let isRefreshing = false;
// let refreshSubscribers = [];
// let inFlightRequests = new Map();

// export const apiRequest = async (
//   method = "get",
//   url = "",
//   payload = { body: null, queries: null },
//   contentType = "application/json",
//   uploadProgress = () => {},
//   customHeader = {}
// ) => {
//   try {
//     let errorRes;
//     let refresh_token;
//     let auth_token;
//     refresh_token = localStorage.getItem("refreshToken");
//     auth_token = localStorage.getItem("auth_token");

//     const requestKey = `${method.toLowerCase()}-${url}-${JSON.stringify(
//       payload
//     )}`;

//     if (inFlightRequests.has(requestKey)) {
//       return inFlightRequests.get(requestKey);
//     }

//     const cancelTokenSource = axios.CancelToken.source();

//     const instance = axios.create({
//       baseURL: API_BASE_URL,
//       headers: {
//         "Content-Type": contentType,
//         Accept: contentType,
//         ...customHeader,
//       },
//       cancelToken: cancelTokenSource.token,
//       onUploadProgress: (progressEvent) => {
//         const percentCompleted = Math.round(
//           (progressEvent.loaded * 100) / progressEvent.total
//         );
//         uploadProgress(percentCompleted);
//       },
//     });

//     // Add a request interceptor
//     instance.interceptors.request.use(
//       function (config) {
//         if (auth_token) {
//           config.headers[`Authorization`] = "Bearer " + auth_token;
//         }
//         return config;
//       },
//       function (error) {
//         return Promise.reject(error);
//       }
//     );

//     function refreshToken(data) {
//       // Implement your token refresh logic here and return a Promise with the new token
//       // For example, you might make a request to the server to get a new token
//       if (refresh_token) {
//         return axios
//           .post("refreshToken", {
//             refreshToken: refresh_token,
//           })
//           .then((res) => {
//             if (res?.data?.status === 200) {
//               auth_token = res?.data?.accessToken;
//               refresh_token = res?.data?.refreshToken;
//               localStorage.setItem("auth_token", res?.data?.accessToken);
//               localStorage.setItem("refreshToken", res?.data?.refreshToken);
//               window.dispatchEvent(new Event("storage"));
//               onTokenRefreshed(res?.data?.accessToken);
//               return res?.data?.accessToken;
//             } else {
//               return Promise.reject("Sign out");
//             }
//           })
//           .catch((error) => {
//             return Promise.reject(error);
//           });
//       } else {
//         return Promise.reject("No refresh token available");
//       }
//     }

//     // Add a response interceptor
//     instance.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         if (error && error?.code == "ERR_NETWORK") {
//           console.log("network error");
//           return;
//         } else {
//           const originalRequest = error.config;
//           if (error?.response?.statusText === "Unauthorized") {
//             localStorage.removeItem("auth_token");
//             localStorage.removeItem("refreshToken");
//             return;
//           }
//           if (
//             error?.response?.data?.tokenExpired === true &&
//             !originalRequest._retry
//           ) {
//             if (isRefreshing) {
//               // Token refresh is in progress, add the request to the queue
//               return new Promise((resolve, reject) => {
//                 refreshSubscribers.push((token) => {
//                   originalRequest.headers["Authorization"] = "Bearer " + token;
//                   resolve(instance(originalRequest));
//                 });
//                 cancelTokenSource.cancel("Request canceled due to duplicate");
//                 reject("Request canceled due to duplicate");
//               });
//             }

//             isRefreshing = true;
//             originalRequest._retry = true;

//             // Call your token refresh function and update the Authorization header
//             return refreshToken(error)
//               .then((newToken) => {
//                 originalRequest.headers["Authorization"] = "Bearer " + newToken;
//                 return instance(originalRequest);
//               })
//               .catch((refreshError) => {
//                 // Handle token refresh failure, e.g., redirect to login page
//                 return Promise.reject(refreshError);
//               })
//               .finally(() => {
//                 isRefreshing = false;
//               });
//           }
//           return Promise.reject(error);
//         }
//       }
//     );

//     const requestPromise = instance[method.toLowerCase()](
//       `${url}${
//         payload.queries ? "?" + new URLSearchParams(payload.queries) : ""
//       }`,
//       payload.body
//     );

//     inFlightRequests.set(requestKey, requestPromise);

//     const res = await requestPromise.finally(() => {
//       inFlightRequests.delete(requestKey);
//     });
//     return res;
//   } catch (error) {
//     return error?.response;
//   }
// };

// function onTokenRefreshed(newToken) {
//   if (newToken) {
//     refreshSubscribers.forEach((subscriber) => {
//       return subscriber(newToken);
//     });
//   }
//   refreshSubscribers = [];
// }
