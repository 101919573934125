import React, { useCallback, useEffect, useState } from "react";
import ProductCardSample from "./ProductCardSample";
import slide1 from "../../assets/img/product/product5.jpg";
import { productListData } from "../../services/productservice";
import { toast } from "react-toastify";

const ProductList = () => {
  const product = {
    image: "https://via.placeholder.com/150",
    hoverImage: "https://via.placeholder.com/150/0000FF/808080",
    title: "Sample Product",
    price: "19.99",
  };

  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: false,
    }));
    productListData({ limit: 100 }).then((res) => {
      if (res && res?.status === 200) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const allProductData = list && list?.data.length > 0 ? list?.data : [];

  return (
    <div className="shop_area py-10 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap lg:flex-nowrap gap-8">
          <div className="lg:w-1/4 w-full">
            <div className="sidebar_widget space-y-6">
              {/* Filter by Price */}
              <div className="widget_list widget_filter p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Filter by Price</h2>
                <form action="#">
                  <input
                    type="text"
                    name="price_min"
                    placeholder="Min Price"
                    className="w-full p-2 border rounded mb-2"
                  />
                  <input
                    type="text"
                    name="price_max"
                    placeholder="Max Price"
                    className="w-full p-2 border rounded mb-2"
                  />
                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                  >
                    Filter
                  </button>
                </form>
              </div>

              {/* Product Categories */}
              <div className="widget_list widget_categories p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">
                  Product Categories
                </h2>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Categories1 <span>(6)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Categories2 <span>(10)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Categories3 <span>(4)</span>
                    </a>
                  </li>
                </ul>
              </div>

              {/* Select By Color */}
              <div className="widget_list widget_categories p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Select By Color</h2>
                <ul className="flex flex-wrap -m-1">
                  {[
                    "black",
                    "blue",
                    "red",
                    "green",
                    "yellow",
                    "purple",
                    "orange",
                    "pink",
                    "brown",
                    "gray",
                    "white",
                    "cyan",
                    "magenta",
                    "lime",
                    "teal",
                    "indigo",
                    "violet",
                    "rose",
                    "peach",
                    "coral",
                    "olive",
                  ].map((color) => (
                    <li key={color} className="m-1">
                      <a
                        href="#"
                        className={`block w-10 h-10 rounded-full border-2   border-blue-500 hover:border-blue-500 transition`}
                        style={{ backgroundColor: color }}
                        title={color.charAt(0).toUpperCase() + color.slice(1)}
                      ></a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Select By Size */}
              <div className="widget_list widget_categories p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Select By Size</h2>
                <ul className="space-y-2">
                  {["XS", "S", "M", "L", "XL", "XXL", "XXXL"].map((size) => (
                    <li key={size}>
                      <a
                        href="#"
                        className="block py-2 text-gray-700 hover:text-blue-500 transition"
                      >
                        {size}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Trending Products */}
              <div className="widget_list widget_trending p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">
                  Trending Products
                </h2>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product A <span>(10)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product B <span>(8)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product C <span>(5)</span>
                    </a>
                  </li>
                </ul>
              </div>

              {/* Popular Products */}
              <div className="widget_list widget_popular p-4 bg-white rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Popular Products</h2>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product X <span>(12)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product Y <span>(9)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block py-2 text-gray-700 hover:text-blue-500 transition"
                    >
                      Product Z <span>(7)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="lg:w-3/4 w-full">
            <div className="shop_title mb-4">
              <h1 className="text-2xl font-bold">Shop</h1>
            </div>

            <div className="shop_toolbar_wrapper mb-4 flex flex-col md:flex-row justify-between items-center">
              <div className="niceselect_option mb-4 md:mb-0">
                <form className="select_option" action="#">
                  <select
                    name="orderby"
                    id="short"
                    className="p-2 border rounded w-full md:w-auto"
                  >
                    <option selected value="1">
                      Sort by average rating
                    </option>
                    <option value="2">Sort by popularity</option>
                    <option value="3">Sort by newness</option>
                    <option value="4">Sort by price: low to high</option>
                    <option value="5">Sort by price: high to low</option>
                  </select>
                </form>
              </div>
              <div className="page_amount">
                <p>Showing 1–9 of 21 results</p>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {allProductData?.map((product) => (
                <div key={product._id} className="flex justify-center">
                  <ProductCardSample
                    imgSrc={slide1}
                    data={product}
                    // hoverImageSrc={banner}
                  />
                </div>
              ))}
              {/* Additional Product Cards */}
            </div>
            {/* <div className="pagination mt-6">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
                className="px-4 py-2 bg-blue-500 text-white rounded-l hover:bg-blue-600 transition"
              >
                Previous
              </button>
              <span className="px-4 py-2 bg-gray-200 text-gray-700">
                Page {currentPage} of {list.pageCount}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= list.pageCount}
                className="px-4 py-2 bg-blue-500 text-white rounded-r hover:bg-blue-600 transition"
              >
                Next
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
