import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import ProductCardSample from "../product/ProductCardSample";
import slide1 from "../../assets/img/product/product5.jpg";
import banner from "../../assets/img/bg/banner2.jpg";
import { toast } from "react-toastify";
import { productListData } from "../../services/productservice";

const PopularProducts = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: false,
    }));
    productListData({ limit: 50 }).then((res) => {
      if (res && res?.status === 200) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const popularProductData = list && list?.data.length > 0 ? list?.data : [];

  return (
    <div className="relative px-6 py-10 my-8 ">
      <div className="w-full text-center">
        <h2 className="text-4xl font-bold text-org-black">
          Unicorn Popular Products
        </h2>
        <p className="text-gray-600 mt-2">
          Contemporary, minimal and modern designs embody the Lavish Alice
          handwriting
        </p>
      </div>
      <div className=" relative mt-3">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={true}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
          }}
          className="trending-products-swiper"
        >
          {popularProductData?.map((product) => (
            <SwiperSlide key={product?._id} className="">
              <ProductCardSample data={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className=" relative mt-3">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={true}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
          }}
          className="trending-products-swiper"
        >
          {popularProductData?.map((product) => (
            <SwiperSlide key={product?._id} className="">
              <ProductCardSample data={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PopularProducts;
